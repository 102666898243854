.Dashboard {
  height: 100%;

  .tool-buttons {
    height: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: flex-start;
    align-content: flex-start;

    .ToolButton {
      flex: 1;
      max-width: 320px;
      min-width: 320px;
      width: 90%;
      margin: 8px;
      background-color: var(--tertiary-color);
      border: 2px solid var(--tertiary-color);
      height: 30vh;
      max-height: 160px;

      .link {
        width: 100%;
        height: 100%;

        .tool-content-container {
          flex-direction: column;
          height: 100%;

          .tool-content {
            display: block;
            display: contents;

            &.content-icon {
              color: var(--primary-color)
            }

            &.content-text {
              line-height: 40px;
              font-size: 18px;
              font-weight: bold;
              color: var(--primary-color)
            }
          }
        }
      }
      &:hover, &:active {
        .content-icon {
          color: var(--secondary-color) !important;
        }
        .content-text {
          color: var(--secondary-color) !important;
        }
      }
    }
  }

}
