.EmailSignatures {
  overflow-y: scroll;
  height: 100%;

  .am-image-picker-upload-btn, .am-image-picker-item-remove {
    display: none;
  }
  .am-image-picker-item-content {
    cursor: pointer;
  }
  .select-picture-btn {
    margin-left: 12px;
    vertical-align: middle;
  }
  .picture-row {
    height: auto !important;
    .am-list-line {
      align-items: flex-start !important;
      padding-bottom: 11px;
      padding-top: 10px;
      .selected-picture {
        height: 120px;
        width: auto;
      }
    }
  }
  .am-image-picker {
    .am-image-picker-list {
      .am-flexbox:first-child {
        .am-flexbox-item:first-child {
          .am-image-picker-item-content {
            position: relative;
            &:before {
              content: "";
              position: absolute;
              display: block;
              top: 4px;
              left: 4px;
              right: 4px;
              bottom: 4px;
              border: 1px solid #333;
              border-radius: 50%;
            }
            &:after {
              content: "geen afbeelding";
              position: absolute;
              display: block;
              width: 100%;
              line-height: 24px;
              top: 50%;
              left: 0;
              margin-top: -12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
