.ProjectInfo {
  height: 100%;

  .am-tag {
    width: 90px;
  }
  .card-box {
    box-sizing: border-box;
    min-height: 120px;
    padding: 25px;
    background-color: #fff;
    border-radius: 0.25rem;
    text-align: center;
    margin-bottom: 1.5rem;
    display: flex;

    p {
      margin: 0;
    }

    .box-icon {
      display: inline-block;
      width: 70px;
      height: 70px;
      line-height: 70px;
      vertical-align: middle;
    }
    .btn-icon {
      box-sizing: border-box;
      margin: 0 15px 0 0;
      font-size: 1.75rem;
      display: inline-block;
      height: 70px;
      width: 70px;
      line-height: 70px;
      border-radius: 50%;
      color: #fff;
    }
    .box-info {
      box-sizing: border-box;
      width: calc(100% - 85px);
      max-width: 120px;
      display: inline-block;
      vertical-align: top;
    }

    .bg-color-success {
      background-color: #3dbd7d;
    }
    .bg-color-info {
      background-color: #01BCD4;
    }
    .bg-color-warning {
      background-color: #ffce3d;
    }
    .bg-color-primary {
      background-color: #108ee9;
    }

    .box-num {
      font-size: 24px;
      line-height: 1;
      margin: 10px 0 5px;
    }

    &:last-child > .box-info {
      max-width: 220px;
    }
  }

  --totals-height: 180px;
  @media screen and (max-width: 900px){
    --totals-height: 240px;
  }
  .totals {
    height: var(--totals-height);
    .card-box {
      width: 25%;
    }
    @media screen and (max-width: 900px) {
      .card-box {
        width: 50%;
      }
    }
  }

  .table {
    height: calc(100% - var(--totals-height) - (2 * 20px)); //2 * margin card
    overflow:hidden;
  }

  --header-height: calc(43px - 24px); //24px padding
  .table-card-header {
    height: var(--header-height);
  }

  .table-card-body {
      height: calc(100% - var(--header-height) - 24px);
      overflow-y: scroll;

  }
}


.rc-table {
  .rc-table-content {
    margin: 8px 16px;
    table {
      border-collapse: collapse;
      width: 100%;
      text-align: left;

      thead > tr > th {
        background-color: var(--tertiary-color);
        transition: background 0.6s ease;
        text-align: left;
        color: var(--primary-color);
        font-weight: 500;
        padding: 24px 16px;
        cursor: pointer;
        &:hover {
          background: var(--primary-color);
          color: var(--tertiary-color);
        }
      }
      tbody > tr {
        td {
          background: #f9f9f9;
          text-align: left;
          font-weight: 500;
          padding: 16px 16px;
        }
        &:nth-child(2n) td{
          background: #f3f3f3;
        }
      }
    }
  }
}
