body {
  text-align: center;

  --primary-color: #ffeb70;
  --secondary-color: #7bebcf;
  --tertiary-color: #252166;
}

.Layout {
  --header-height: 60px;
  height: 100vh;
  .header {
    height: var(--header-height);
    max-height: 60px;
    .single-foo-menu {
      position: absolute;
      z-index: 90 !important;
      width: 100%;
    }

    .single-menu-active .single-top-nav-bar {
      z-index: 90;
    }

    .single-top-nav-bar {
      position: relative;
      background-color: var(--tertiary-color);
      color: var(--primary-color);
      height: var(--header-height);
    }
    .am-navbar-title {
      .logo-wrapper {
        height: calc(-16px + var(--header-height) * 2);
        position: absolute;
        top: 0;
        .logo {
          max-height: 100%;
          height: 100%;
          display:block;
          padding: 8px;
          svg {
            height: 100%;

            .primary {
              fill: var(--primary-color);
            }
            .secondary {
              fill: var(--tertiary-color);
            }
          }
        }
      }
    }

    .am-navbar-right {
      svg {
        margin-left: 8px;
      }
    }
    .menu-mask {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.4;
      z-index: 89;
    }
  }
  .body {
    height: calc(100% - var(--header-height));
    background-color: #e4e4e4;

    .content-container {
      height: calc(100% - 68px);
      margin: 64px 8px 8px 8px;
      //background-color: #fdfdfd;
      color: #282c34;

      a, a:visited {
        color: #035f9c;
        &:hover, &:active, &:focus {
          color: #035f9c;
        }
      }
    }
    .card-positioning {
      margin: 0 20px;
    }
  }
}

/* fixed to antd-mobile usability and styling */
body {

  .am-button, .am-modal-button {
    cursor: pointer;
  }

  .am-modal-wrap {
    width: 100%;

    .am-modal-content {
      width: calc(100% - 32px);
      max-height: calc(100% - 32px);
      max-width: 600px;
      margin: 16px auto;
      --modal-header-footer-height: 60px;
      box-sizing: border-box;

      .am-modal-header,
      .am-modal-footer {
        height: var(--modal-header-footer-height);
        padding: 0;
        & > div {
          line-height: var(--modal-header-footer-height);
          height: var(--modal-header-footer-height);
          & > a {
            height: var(--modal-header-footer-height);
            line-height: var(--modal-header-footer-height);
          }
        }
      }
      .am-modal-body {
        height: calc(100% - 2 * var(--modal-header-footer-height));
        overflow-y: scroll;
      }
    }
  }
}
