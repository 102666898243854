.Card {
  --border: 1px solid rgba(192,192,192,0.25);
  margin: 20px;
  border: var(--border);
  box-sizing: border-box;
  background-color: #fdfdfd;

  .card-header {
    padding: 12px 24px;
    border-bottom: var(--border);
    h2 {
      font-size: 16px;
      margin: 0;
    }
  }

  .card-body {
    min-height: 60px;
    padding: 12px 24px;
  }

}
