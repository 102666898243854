.Content {
  overflow-y: scroll;
  height: 100%;
  .author {
    font-weight: bold;
    font-size: 10px;
    text-align: right;
  }

}
